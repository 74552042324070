$background: #1d1e1e;
$chars: "A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","0","1","2","3","4","5","6","7","8","9";

html, body {
  height: 100vh;
  margin: 0px;
}

#publicdata {
  background-color: $background;
  font-family: 'Fire Sans', sans-serif;
  width: 100wh;
  height: 100vh;
  margin: 0px;
}
.publicdatah1 {
  color: yellow;
}
i {
  margin-right: 0.5em;
}
#publicdata.footer {
  color: rgba(255,255,255,0.3);
  font-size: 0.8em;
  margin-top: 2em;
}

.departure-board {
  display: inline-flex;
}
.letter {
  background-color: #000;
  border-right: 1px solid $background;
  color: #000;
  display: flex;
  flex-direction: column;
  height: 40px;
  text-align: center;
  width: 40px;
}
.letter::before {
  background-color: #000;
  border-bottom: 1px solid rgba(255,255,255,0.3);
  font-size: 40px;
  height: 20px;
  line-height: 40px;
  overflow: hidden;
  width: 100%;
  z-index: 20;
}
.letter::after {
  background-color: #000;
  flex-grow: 0;
  font-size: 40px;
  height: 40px;
  line-height: 40px;
  margin-top: -50%;
  overflow: hidden;
  width: 100%;
  z-index: 10;
}
/* Blank letter */
.letter-blank::before {
  content: ' ';
}
.letter-blank::after {
  content: ' ';
}
/* dynamic function for character classes */
@each $char in $chars {
  .letter-#{$char}::before {
    content: $char;
  }
  .letter-#{$char}::after {
    content: $char;
  }
}
/* Letter animations */
@for $i from 1 through 50 {
  .departure-board span:nth-child(#{$i}):before {
    animation: flip 1s 1 ease-in-out;
    animation-delay: #{$i/4}s;
    animation-fill-mode: forwards;
    backface-visibility: hidden;
    transform-style:preserve-3d;
    transition-delay: #{$i/4}s;
  }
  .departure-board span:nth-child(#{$i}):after {
    animation: flip 1s 1 ease-in-out;
    animation-delay: #{$i/4}s;
    animation-fill-mode: forwards;
    backface-visibility:hidden;
    transition-delay: #{$i/4}s;
    transform-style:preserve-3d;
  }
}
@keyframes flip {
  0%{
    color: #000;
    transform:rotateX(0deg);
  }
  20%{
    content: nth($chars, random(length($chars)));
    transform:rotateX(360deg);
  }
  40%{
    color: #fff;
    content: nth($chars, random(length($chars)-1));
    transform:rotateX(0deg);
  }
  60%{
    color: #fff;
    content: nth($chars, random(length($chars)-2));
    transform:rotateX(360deg);
  }
  80%{
    color: #fff;
    content: nth($chars, random(length($chars)-3));
    transform:rotateX(0deg);
  }
  100%{
    color: #fff;
    transform:rotateX(360deg);
  }
}
